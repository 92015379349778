<template>
    <div>
        <b-card-code :title="'update Categories: ' + nameCat" :before-change="validationForm">
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row>
                        <b-col cols="12">

                            <b-row>
                                <b-col md="12" v-for="(category, index)  in  accessoryData.categories" :key="index">
                                    <b-row>
                                        <b-col md="6">
                                            <validation-provider #default="{ errors }" name="category" rules="required">
                                                <b-form-group v-if="category" label="Select Category"
                                                    label-for="category" :state="errors.length > 0 ? false : null">
                                                    <v-select v-model="accessoryData.categories[index]"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                                                        value="id"
                                                        :state="accessoryData.categories[index] === null ? false : true"
                                                        :options="categories.map(category => ({ id: category.id, name: category.translation.name }))"
                                                        :clearable="false" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6" class="mt-2">
                                            <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" class="ml-4" v-show="index != 0"
                                                variant="outline-danger"
                                                @click.prevent="removeCategory(index)">Remove</b-button>
                                        </b-col>
                                    </b-row>

                                </b-col>


                                <b-col cols="12" class="mt-4">
                                    <b-button variant="success" @click.prevent="addNewCategory">Add New
                                        Category</b-button>
                                </b-col>


                                <b-col cols="12">
                                    <hr />
                                </b-col>
                            </b-row>
                        </b-col>

                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-button variant="primary" @click.prevent="validationForm">Submit</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-card-code>
        <!-- error handelr -->
        <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
            variant="danger">
            <h4 class="alert-heading">
                Alert
            </h4>
            <div class="alert-body">
                <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{ value }}</li>
                </ul>
            </div>
        </b-alert>
    </div>
</template>

<script>

import { heightTransition } from "@core/mixins/ui/transition";
import { required, integer } from "@validations";
export default {

    mixins: [heightTransition],
    data() {
        return {
            errors_back: [],
            showDismissibleAlert: false,
            required,
            integer,
            id: 0,
            translations: {
                locale: "",
                name: "",
            },
            tranLocaleSelect: null,
            accessoryData: {},
            localeOptions: [
                {
                    value: "en",
                    text: "English",
                },
                {
                    value: "ar",
                    text: "Arabic",
                },
            ],
            nameCat: "",
            categories: [],
        };
    },
    methods: {
        // submit Trans
        validationForm() {
            this.$refs.simpleRules.validate().then((success) => {
                if (success) {
                    console.log('this.categories', this.categories);
                    // this.translations.locale = this.categories;
                    const url = this.$session.get("lang");
                    const categories = this.accessoryData.categories.map(category => ({ id: category.id})  );

                    axios
                        .post(
                            "accessories/" + this.id + "/categories/store",
                            categories
                        )
                        .then((result) => {
                            this.$swal({
                                position: "center",
                                icon: "success",
                                title: "Your work has been saved",
                                showConfirmButton: false,
                                timer: 1500,
                            });
                            this.$router.back(1);
                        })
                        .catch((err) => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: "Error!",
                                showConfirmButton: false,
                                timer: 1500,
                            });
                            this.errors_back.length = 0;
                            if (err.response.data.data != null) {
                                if (this.isString(err.response.data.data)) {
                                    this.errors_back.push({
                                        error: err.response.data.data,
                                    });
                                } else {
                                    this.errors_back = err.response.data.data;
                                }
                                this.showDismissibleAlert = true;
                            } else {
                                this.errors_back = [];
                                this.errors_back.push({
                                    error: "internal server error",
                                });
                                this.showDismissibleAlert = true;
                            }
                        });
                }
            });
        },
        isString(value) {
            return typeof value === "string" || value instanceof String;
        },

        addNewCategory() {
            this.accessoryData.categories.push({
                id: '',
                name: ''
                // Add other properties as needed
            });
        },
        removeCategory(index) {
            this.accessoryData.categories.splice(index, 1); // Remove the category at the specified index
        },

        getCategories() {
            axios
                .get('categories')
                .then((result) => {
                    this.categories = result.data.data
                })
                .catch((err) => {
                    this.errors_back.length = 0
                    if (err.response.data.data != null) {
                        if (this.isString(err.response.data.data)) {
                            this.errors_back.push({
                                error: err.response.data.data,
                            })
                        } else {
                            this.errors_back = err.response.data.data
                        }
                        this.showDismissibleAlert = true
                    } else {
                        this.errors_back = []
                        this.errors_back.push({
                            error: 'internal server error',
                        })
                        this.showDismissibleAlert = true
                    }
                })
        },
    },
    created() {
        this.id = this.$route.params.id;
        const url = this.$session.get("lang");
        this.getCategories();
        axios
            .get("accessories/" + this.id)
            .then((result) => {
                this.accessoryData = result.data.data;
                this.accessoryData.categories = this.accessoryData.categories.map(category => ({ id: category.id, name: !category.translation.some(t => t.locale === 'en') ?  category.translation[1].name : category.translation[0].name  }))

                //   this.nameCat = data.translation[0].name;
            })
            .catch((err) => {
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                    if (this.isString(err.response.data.data)) {
                        this.errors_back.push({
                            error: err.response.data.data,
                        });
                    } else {
                        this.errors_back = err.response.data.data;
                    }
                    this.showDismissibleAlert = true;
                } else {
                    this.errors_back = [];
                    this.errors_back.push({
                        error: "internal server error",
                    });
                    this.showDismissibleAlert = true;
                }
            });
    },

};

</script>

<style lang="scss"></style>